<template>
  <div class="navigation">
    <div class="right-nav">
      <ul>
        <li v-for="item in navList" :key="item.id" @click="toggleRoute(item.id, item.route)"
          :class="{ activeLi: item.id == activeId }">
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
     <div class="left-nav">
      <span style='margin-right:7px;'>
        <img v-if="shopInfo.edition!='高级版' && shopInfo.edition!='默认版'" style="vertical-align: middle;" :src="OSS + ('/ordinary_icon.png')" alt="">
        <img v-else style="vertical-align: middle;" :src="OSS + ('/vip_icon.png')" alt="">
      </span>
      <span>
        {{ shopInfo.shop_name }} | <span>有效期至 {{ shopInfo.expiration_time ? shopInfo.expiration_time : '--' }}</span>
      </span>
      <span @click="jumpRenew" class="buy_now">
        立即续费
      </span>
      <div class="top_txt_list">
        <span class="vertical"></span>
        <span @click="askhelp">联系客服</span>
        <span @click="useTutorial">查看教程</span>
        <span @click="exit">退出</span>
        <span @click="putOnTheDesktop('私密打 自动分销打印', 'url')" style="margin-right:20px;">放到桌面</span>
         <span class="vertical"></span>
      </div>
      <div class="app_logo">
        <img :src="OSS + ('/app_new_icon.png')" alt="">
        <span style="transform: translateY(2px);">
          <span style="font-weight:600;font-size:14px;">私密打</span>
          <span style="font-size:12px;margin-left:6px;">自动分销打印</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import test_print_data from "./test_print_data";

export default {
  name: "TopNavigation",
  computed: {
    ...mapState({
      shopInfo: (state) => state.setting.get_shop_info.data,
      navList: (state) => state.nav.navList,
    }),
    activeId: {
      get() {
        return this.$store.state.nav.activeId;
      },
      set(val) {
        this.$store.commit("nav/save", { activeId: val });
      },
    },
  },
  data() {
    return {
      stroeInfoShow: false,
      visiable: 1,
      styleType: '',
      shopInfoShow: false,
    };
  },
  methods: {
    // test
    test() {
      console.log(test_print_data, '---test_print_data')
      let print_params = {
        printer: "导出为WPS PDF",
        preview: false,
        data: test_print_data.data,
      }
      // let TaskID = window.kuaishou_print.doPrint(print_params)
      // let TaskID = window.douyin_print.doPrint(print_params)
      let TaskID = window.cainiao_print.doPrint(print_params)
    },
    
   
    toggleRoute(id, route) {
      // 判断当前是那个路由
       if(route=='/encryption'){
        sessionStorage.setItem('searchType',2)
      }else{
        sessionStorage.setItem('searchType',1)
      }
      if (id == 4) {
        this.activeId = id;
        this.$router.push(route + '/printRecordSearch');
      } else {
        this.activeId = id;
        this.$router.push(route);
      }
    },
    handleSelect() { },
    exit() {
      // 退出 清楚token并跳转授权页
      window.sessionStorage.removeItem("token");
      // 获取登录链接并跳转
      // this.$store.dispatch('setting/getAuthorUrl').then(res=> {
      // // getAuthorUrl().then((res) => {
      //   if (res.code == 10019) {
      //     window.location.href = res.data.url;
      //   }
      //   reject();
      // });
      // window.close();
      window.location.href = 'https://login.1688.com//member/logout.htm'
    },
    useTutorial() {
      // const { href } = this.$router.resolve({ name: "usetutorial" });
      // window.open("usetutorial.html", "_blank");
      let path = `${window.location.protocol}//${window.location.host}/#/usetutorial`
      window.open(path);
    },
    jumpRenew() {
      window.open('https://pc.1688.com/product/detail.htm?productCode=SBrXa5Yd1KaWclcWKV87ctrevh98QbIimpiT9kqpXec%3D&productType=GROUP');
    },
    putOnTheDesktop(a, b, c = window.location.host) {
      var d = '' + a + '.' + b;
      var e = '[InternetShortcut]' + '\n' + 'URL=' + c;
      this.saveToDesktop(d, e);
    },
    saveToDesktop(fileName, data) {
      var explorer = navigator.userAgent.toLowerCase();
      if (explorer.indexOf("trident") > 0) {//IE 5+
        var wss = new ActiveXObject("WScript.Shell");
        var desktopUrl = wss.SpecialFolders("Desktop");
        var fso = new ActiveXObject("Scripting.FileSystemObject");
        var tf = fso.CreateTextFile(desktopUrl + "\\" + fileName, true);
        tf.Write(data);
        tf.Close();
      } else {//fireFox chrome
        var urlObj = window.URL || window.webkitURL || window;
        var blob = new Blob([data]);
        var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
        link.href = urlObj.createObjectURL(blob);
        link.download = fileName;
        saveClick(link);
      }

      function saveClick(obj) {
        var e = document.createEvent("MouseEvents");
        e.initMouseEvent(
          "click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
        );
        obj.dispatchEvent(e);
      }
    },
    buyRecordBtn() {
      // this.$router.resolve({ name: "buyRecord" })
      // this.$router.push('/buyRecord')
      let path = `${window.location.protocol}//${window.location.host}/#/buyRecord`
      window.open(path);
    },
    backtrack() {
      window.open("https://fuwu.1688.com/")
    },
    askhelp() {
      window.open("https://amos.alicdn.com/getcid.aw?spm=a261y.7663282.autotrace-topbar.154.4e6e66b9A4r8FI&v=3&groupid=0&s=1&charset=utf-8&uid=%E5%85%B4%E4%B8%9A%E5%AE%9D%E4%BA%91%E4%BB%93&site=cnalichn")
    },
  },
  mounted() {
    console.log(this.$route,'------route')
    let routerVal=window.location.hash
    if(routerVal=='#/encryption'){
        sessionStorage.setItem('searchType',2)
    }else{
         sessionStorage.setItem('searchType',1)
    }
    this.$nextTick(function () {
      this.activeId = window.sessionStorage.getItem("activeId");
      this.$store.dispatch('setting/getShopInfo').then(() => {
        this.shopInfoShow = true
        sessionStorage.setItem('shop_name', this.shopInfo.shop_name)
      })
    });
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  box-shadow: 0px 4px 4px rgba(186, 186, 186, 0.25);
  background-color: #fff;
  width: 100%;
  min-width: 1200px;
  height: 50px;
}

.right-nav {
  height: 100%;
}

.right-nav ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: 10px;
  height: 100%;
  /* margin-top: 7px; */
}

.right-nav ul li {
  list-style: none;
  text-align: center;
  cursor: pointer;
  color: #000;
  font-size: 14px;
  width: 66px;
  margin: 0px 5px;
  /* padding: 6px 5px; */
  border: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid transparent;
  padding-top: 3px;
}


.right-nav .activeLi {
  color: #FA7B29;
  border-bottom: 3px solid #FA7B29;
}


.left-nav {
  color: #595959;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  height: 100%;
}



/* 新写 */
.left-nav .buy_now {
  cursor: pointer;
  display: inline-block;
  width: 75px;
  height: 29px;
  line-height: 29px;
  text-align: center;
  font-size: 12px;
  background: linear-gradient(269.85deg, #F9D895 0.13%, #FEE6B4 99.89%);
  border-radius: 4px;
  color: #521400;
  margin: 0 20px;
 }
  .left-nav .top_txt_list {
    display: flex;
    align-items: center;
  }
  .left-nav .top_txt_list span {
    margin-right: 26px;
    cursor: pointer;
    font-size: 12px;
  }
  .left-nav .app_logo {
    color: #FA7B29;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .left-nav .app_logo span {
    margin-left: 4px;
  }
  .left-nav .top_txt_list .vertical {
    display: inline-block;
    width: 2px;
    height: 20px;
    background-color: #D2D2D2;
    margin-right: 20px;
    cursor: context-menu;
  }

/* 新写结束 */

.left-nav ul {
  margin: 0;
  padding: 0;
}

.right-menu {
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.white {
  color: white;
  font-size: 12px;
}

.left-nav>>>.el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
}

.left-nav>>>.el-submenu .el-submenu__title {
  font-size: 12px;
}

.left-nav>>>.el-menu--horizontal>.el-menu-item {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  color: white;
}

.left-nav>>>.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: white;
}

/* .update{
  margin: 0 10px;
} */
.left-nav>>>.el-submenu .el-submenu__title i {
  color: #ffffff !important;
}

.stroeInfoShow {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  padding: 5px 3px 0 3px;
  background-color: #ffffcc;
  border: 1px solid #f8be9f;
  border-bottom: none;
  position: relative;
  z-index: 202;
  color: #f6891e;

}

.stroeInfoNo {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  padding: 5px 3px 0 3px;
  border-bottom: none;
  position: relative;
  z-index: 100;
  color: #ffff00;

}

.hideDiv {
  width: 230px;
  position: absolute;
  top: 29px;
  left: -1px;
  background-color: #ffffcc;
  border: 1px solid #f8be9f;
  border-top: none;
  border-radius: 0 0 8px 8px;
  color: #000;
  padding: 10px;
  cursor: default;
}

.hideDiv div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hideDiv div div {
  height: 30px;
  background-color: #ff740d;
  padding: 0 5px;
  line-height: 29px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  /* width: 68px; */
}
</style>