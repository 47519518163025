import { getAuthorUrl, getToken } from "@/api/login.js";
function parseUrl(url) {
  let result = {};
  let queryString = url.split('?')[1];
  if (queryString) {
      let pairs = queryString.split('&');
      pairs.forEach(pair => {
          let [key, value] = pair.split('=');
          result[key] = value;
      });
  }
  return result;
}
export default function Token (){
  console.log("---dev_get_token")
  return new Promise((resolve, reject) => {
    let token=window.sessionStorage.getItem("token");
    if(!token || token == "null" || token=="undefined"){
      // 没有token code换token
      // let url = window.location.href;
      // let urlArr = url.split("=");
      let data = parseUrl(window.location.href)
      if (data.code) {
        // 有code code换token
        // let codeStr = urlArr[1];
        // 获取token
        getToken({ code: data['code'], state: data['state'] }).then((res) => {
          var tokenStr = res.data.token;
          if(tokenStr!=null){
            window.sessionStorage.setItem("token", tokenStr);
          }
          resolve();
        });
      }else {// 没有code 跳转登录换code
        window.sessionStorage.removeItem("token");
        // 获取登录链接并跳转
        getAuthorUrl().then((res) => {
          if (res.code == 10019) {
            window.location.href = res.data.url;
          }
          reject();
        });
      }
    }else{
      resolve();
    }
  });
}