import axios from "axios"
import store from '@/store';
import { Loading, Message, MessageBox } from 'element-ui';
// 如果需要进度条
// import 'normalize.css/normalize.css' 

let loading = null; // 全局loading

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.baseURL = process.env.VUE_APP_BASE_API

// 2.请求拦截器
axios.interceptors.request.use(
	config => {
		// 添加token
		let token = window.sessionStorage.getItem("token");
		if (token) {
			config.headers['x-token'] = token;
		}
		if(config["data"] && config["data"]['NO_SHOW_LOADING']){
			
		}else{
			loading = Loading.service({text:"加载中..."});
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

// 3.响应拦截器
axios.interceptors.response.use(
	response => {
		loading.close()
		var res = response.data;
		if(res.code == 11){
			store.dispatch('batchprint/GetTaobaoAuthURL').then(res=>{
				window.open(res.data)
			})
		}
		if(res.code==10001 ||res.code==10003 ||res.code==10004 || 
			  res.code==10005 || res.code==10006||res.code ==10019 ){
			// token失效或过期，清除sessionStorage中的token
			window.sessionStorage.removeItem("token");
			return res;
		}else if (res.code == 20000 ||res.code == 30000||res.code == 30001) {
			// 请求参数错误
			Message.error(res.message);
			return res;
		}else if( res.code == 44444 ){ // 抖音打单授权跳转
			MessageBox.alert(`授权抖音后请刷新页面`,'授权', {
				confirmButtonText: '去授权',
				dangerouslyUseHTMLString: true,
				closeOnClickModal: false,
				callback: action => {
					if(action=="confirm"){
						window.open(res.message)
					}
				}
			});
		}else if( res.code == 55555 ){ // 快手打单授权跳转
			MessageBox.alert(`授权快手后请刷新页面`,'授权', {
				confirmButtonText: '去授权',
				dangerouslyUseHTMLString: true,
				closeOnClickModal: false,
				callback: action => {
					if(action=="confirm"){
						window.open(res.message)
					}
				}
			});
		}else{
			return res
		}
	},
	error => {
		return Promise.reject(error);
	});

// 封装一个请求函数
export default function (method, url, data = null) {
	method = method.toLowerCase();
	if (method == 'post') {
		return axios.post(url, data);
	}
	else if (method == 'get') {
		return axios.get(url, { params: data });
	}
	else {
		console.log('未知方法' + method);
		return false;
	}
}

