import { getAppOrder } from '@/api/encryption.js'

// 加密打印
const state = {
    encryptionDialog:false
}

const mutations = {
	SAVE(state,data){
		state = Object.assign(state,data)
	},
}

const actions = {
	// 更新店铺订购信息
	getAppOrder({ commit }) {
		return new Promise((resolve, reject) => {
			sessionStorage.setItem('edition',0)
			getAppOrder().then(res => {
				if(res.code==0){
					// 1为高级版，默认版，0为初级版本
					if(res.data.edition=='高级版' || res.data.edition=='默认版'){
						sessionStorage.setItem('edition',1)
					}
				}
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	}
	
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}