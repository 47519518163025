import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
// 复制剪切板
import VueClipboard from 'vue-clipboard2'
// iconfont
import '@/styles/iconfont/iconfont.css'
// elementUI
import '@/styles/theme/index.css'
import ElementUI from 'element-ui'
// 样式文件
import '@/styles/global.less'
import '@/styles/elementReset.less'
// 过滤器文件
import '@/utils/filters.js'
// moment
import moment from "moment";
// lodash
import _ from 'lodash';
// 获取taoken
import getToken from '@/utils/getToken';//正式
import testToken from '@/utils/testToken';//测试
// lodop初始化
import { lodop_init } from '@/utils/lodopPrint.js'
import {OSS,DownLoad_DouYin,DownLoad_KuaiShou} from "@/utils/PublicLink";

// 检测CLodop打印机组件
window.onload = () => {
  console.log('------onload检测CLodop打印机组件')
  lodop_init().then(res => {
    window.component_installed_type = res
    if (!res) {
      this.$message.error("提示: lodop打印组件无法链接");
    }
  }).catch((err) => {
    console.log("lodop打印组件错误::", err);
  })
}
const Tools = { _, moment,OSS,DownLoad_DouYin,DownLoad_KuaiShou } // 统一注入工具，需要什么将工具添加其到对象中即可
for(let i in Tools){
  Vue.prototype[i] = Tools[i]
}

Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(ElementUI)

let GetTokenFn = getToken
if( process.env.VUE_APP_ENV=="dev" ){ // 开发环境下代理标识
  GetTokenFn = testToken
}

GetTokenFn().then(res=>{
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
  