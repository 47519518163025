import request from '../plugins/axios.js'

// 查询接口

// 获取查询结果
export function getSearchResult( params={} ) {
  let type=sessionStorage.getItem('searchType')
  params["NO_SHOW_LOADING"] = true
  params["search_type"] = type==2?2:1
  return request(
    'post',
    '/PrintSearch/PrintSearchNew',
    params
  )
}