import Vue from 'vue'
import Vuex from 'vuex'
// 导入module
import print from './modules/print'
import batchprint from './modules/batchprint'
import singleprint from './modules/singleprint'
import setting from './modules/setting'
import introduct from './modules/introduct'
import utilities from './modules/utilities.js'
import nav from "./modules/nav"
import encryption from "./modules/encryption"


Vue.use(Vuex)

// 自动导入modules文件夹下的所有js文件
// const modulesFiles = require.context('./modules', true, /\.js$/)
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)
//   modules[moduleName] = value.default
//   return modules
// }, {})

const store = new Vuex.Store({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	// 绑定module
	modules: {
		print,
		batchprint,
		singleprint,
		setting,
		introduct,
		utilities,
		nav,
		encryption
	}
})

export default store
