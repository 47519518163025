import { getSearchResult } from '@/api/search.js'
import { getOrderInfo } from '@/api/print.js'
import { updateExpressState,decryptBuyer,OrderMemoAdd,updateSign  } from '@/api/singleprint.js'
import { updateFahuoState } from '@/api/batchprint.js'
// 单个打印Module

const state = {
	// 标识当前显示哪个组件
	isShow: 'index',
	// 无数据
	singleprint_nodata: false,
	// 查询表单数据
	searchFormData: {
		// 查询按什么时间
		Time: '1',
		// 开始时间
		startTime: '',
		// 结束时间
		endTime: '',
		// 订单状态
		status: '',
		// 按什么查询
		ww_baby_count_money: '',
		// 订单类型
		trade_type: '',
		// 打印状态
		print_state: '',
		// 退款状态
		refund_state: '',
		// 留言备注
		remark_type: '1',
		// 买家旺旺
		wangwang: '',
		// 商品名称
		productName: '',
		// 页码
		pageNum: '1',
		// 每页个数
		limit: '10',
		delivery_limit_type:''
	},
	// 查询结果数据
	resultList: {
		data: {},
		total: 0,
		per_page: 0,
		buyer_count: ''
	},
	// 隐藏显示分页组件参数
	pageHide: true,
	// 查询loading参数
	resultLoading: false,
	// 订单详细信息loading参数
	orderLoading: false,
	// 展示详细信息的订单id
	orderId: '',
	// 对应订单id的订单详细信息
	orderInfo: {},
	// 解密信息
	decryptInfo:{},
	// 单个打印时要打印的订单信息
	printOrderInfo: {},
	// 单个打印时要打印的快递模板
	printExpressTemplate: '',
	printExpressBgimg: '',
	// 单个打印时要打印的发货模板
	printFahuoTemplate: '',
	printFahuoBgimg: '',
	// 单个打印时使用的打印机
	printer: '',
	// 单个打印时打印的页数
	printNum: '',
	// 订单标记状态
	circularType: '',
	// 进度条
	progressBarPopup: false,
}

const mutations = {
	save:(state,data)=>{
		state = Object.assign(state,data)
	},
	SET_RESULT_LIST: (state, data) => {
		state.resultList = data
	},
	SET_ISSHOW: (state, data) => {
		state.isShow = data
	},
	SET_CIRCULARTYPE: (state, data) => {
		state.circularType = data
	},
	SET_PAGENUM: (state, data) => {
		state.searchFormData.pageNum = data
	},
	SET_PAGEHIDE: (state, data) => {
		state.pageHide = data
	},
	// 当前订单信息
	SET_ORDERID: (state, data) => {
		state.orderId = data
	},
	SET_ORDERINFO: (state, data) => {
		state.orderInfo = data
	},
	// 解密信息
	GET_DECRYPTINFO: (state, data) => {
		state.decryptInfo = data
	},
	// 修改加载参数
	SET_RESULT_LOADING: (state, data) => {
		state.resultLoading = data
	},
	SET_ORDER_LOADING: (state, data) => {
		state.orderLoading = data
	},
	// 修改打印相关信息
	SET_PRINT_ORDER_INFO: (state, data) => {
		state.printOrderInfo = data
	},
	SET_PRINT_EXPRESS_TEMPLATE: (state, data) => {
		state.printExpressTemplate = data
	},
	SET_PRINT_EXPRESS_BGIMG: (state, data) => {
		state.printExpressBgimg = data
	},
	SET_PRINT_FAHUO_TEMPLATE: (state, data) => {
		state.printFahuoTemplate = data
	},
	SET_PRINT_FAHUO_BGIMG: (state, data) => {
		state.printFahuoBgimg = data
	},
	SET_PRINTER: (state, data) => {
		state.printer = data
	},
	SET_PRINT_NUM: (state, data) => {
		state.printNum = data
	},
	SET_PROGRESS_BAR_POPUP:(state, data)=>{
		state.progressBarPopup = data
	},
}

const actions = {
	// 获取查询结果
	get_search_result({ commit, state },no_params) {
		if(!state.progressBarPopup){
			commit('SET_RESULT_LOADING', true);
		}
		var params = JSON.parse(JSON.stringify(state.searchFormData))
		params.endTime = params.startTime[1];
		params.startTime = params.startTime[0];
		no_params && (params=undefined) // 去掉查询参数
		return new Promise((resolve, reject) => {
			let query_state = true // 通过查询中状态控制轮询间隔
			query_fn()
			let query_timer = setInterval(query_fn,5000)
			// 定义查询
			async function query_fn (){
				if(query_state){
					query_state = false
					let res = await getSearchResult(params)
					if (res.data.last_page != '1') {
						commit('SET_PAGEHIDE', false)
					}
					if(res.data.task_progress || res.data.task_progress == 0){
						query_state = true
						commit('SET_PROGRESS_BAR_POPUP', res.data.task_progress)
					}else{
						commit('SET_PROGRESS_BAR_POPUP', false)
						for(let i in res.data.data) {
							res.data.data[i].checkedGoods = res.data.data[i].order_sub
							if(res.data.data[i].is_merge_order==1){
								res.data.data[i].showUpAdres=1
								for(let k in res.data.data[i].merge_order){
									res.data.data[i].merge_order[k].showUpAdress=1
								}
							}else{
								res.data.data[i].showUpAdres=0
							}
						}
						commit('SET_RESULT_LIST', res.data)
						clearInterval(query_timer)
						commit('SET_RESULT_LOADING', false);
						resolve(res)
					}
				}
			}
		})
	},
	// 获取订单详细信息
	get_order_info({ commit, state }) {
		commit('SET_ORDER_LOADING', true);
		return new Promise((resolve, reject) => {
			getOrderInfo({ orderId: state.orderId }).then(res => {
				commit('SET_ORDERINFO', res.data.result)
				commit('SET_ISSHOW', 'ResultInfo');
				commit('SET_ORDER_LOADING', false);
				// commit('SET_ORDERINFO', res.data.result);
				resolve()
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 获取解密信息
	decryptBuyer({ commit },params){
		return new Promise((resolve,reject)=>{
			decryptBuyer({orderId:params.orderId,caid:params.caid}).then(res=>{
				if(res.data.data){
					commit('GET_DECRYPTINFO',res.data.data.result.result);
				}
				resolve(res)
			}).catch(err=>{
				reject(err)
			})
		})
	},
	// 修改备注
	OrderMemoAdd({ commit },params){
		return new Promise((resolve,reject)=>{
			OrderMemoAdd(params).then(res=>{
				if(res.data.data){
					// commit('GET_DECRYPTINFO',res.data.data.result.result);
				}
				resolve(res)
			}).catch(err=>{
				reject(err)
			})
		})
	},
	// 修改标记
	updateSign({ commit },params){
		return new Promise((resolve,reject)=>{
			updateSign(params).then(res=>{
				if(res.data.data){
					// commit('GET_DECRYPTINFO',res.data.data.result.result);
				}
				resolve(res)
			}).catch(err=>{
				reject(err)
			})
		})
	},
	// 更新发货状态
	update_fahuo_state({ }, params) {
		return new Promise((resolve, reject) => {
			updateFahuoState(params).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	// 更新快递状态
	update_express_state({ }, params) {
		return new Promise((resolve, reject) => {
			updateExpressState(params).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}