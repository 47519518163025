<template>
  <div id="app">
    <div class="app-container bg-color">
      <!-- 顶部导航 -->
      <topnavigation />
      <!-- 主体 -->
      <router-view />
      <!-- 右侧菜单 -->
      <!-- <right-tips/> -->
    </div>
    <appDialog @closePopup="()=>{this.dialogShow=false}" v-if="dialogShow"/>
    <!-- 打印失败提示框 -->
    <GetExpressErrorPopup v-if="GetExpressError" :dataSrc="GetExpressError" @closePopup="() => { this.GetExpressError = false }"></GetExpressErrorPopup>
  </div>
</template>

<script>
import GetExpressErrorPopup from "@/UI/GetExpressErrorPopup";
import appDialog from "@/components/appDialog";
import topnavigation from "@/components/TopNavigation";
import RightTips from "@/components/RightTips";
// lodop初始化
import {lodop_init} from '@/utils/lodopPrint.js'
import cainiao_print from "@/utils/cainiao_print";
import douyin_print from "@/utils/douyin_print";
import kuaishou_print from "@/utils/kuaishou_print";

export default {
  components: { 
    topnavigation, 
    RightTips,
    appDialog,
    GetExpressErrorPopup,
  },
  name: "App",
  computed: {
    GetExpressError: {
        get() {
          return this.$store.state.print.GetExpressError;
        },
        set(val) {
          this.$store.commit("print/SAVE", {GetExpressError: val});
        }
      },
  },
  data() {
    return {
      dialogShow: false,
    };
  },
  methods: {
  },
  mounted() {
    // 获取一些必要的数据
    this.$store.dispatch("setting/getConfig")
    this.$store.dispatch('print/get_express_template');
    // this.$store.dispatch('print/get_fahuo_template');
    this.$store.dispatch('print/get_express_list');
    this.$store.dispatch('print/get_fahuo_consigner_list');
    this.$store.dispatch('print/get_express_consigner_list');

    // 检测CLodop打印机组件
    window.onload = ()=>{
      lodop_init().then(res=>{
        window.component_installed_type = res
        if(!res){
          this.$message.error("提示: lodop打印组件无法链接");
          this.dialogShow = true
        }
      })
    }
    // 初始化菜鸟打印机组件
    let cainiao_params = new Object()
    cainiao_params.onmessage= (event) => {
      console.log(event, "---onmessage")
      let response = JSON.parse(event.data);
      if (response.cmd == 'getPrinters') { // 监听打印机列表
        let print_list = response.printers.map(item=>{
          return item.name
        })
        console.log(print_list,'---菜鸟打印机列表获取成功')
        window.cainiao_print_list = print_list // 别管用不用得上,先保存一份打印机列表
      }else if(response.cmd == 'print'){ // 监听打印事件
        console.log(response,'---print')
      }else if(response.cmd == 'getTaskStatus'){ // 监听打印事件
        console.log(response,'---getTaskStatus')
      }
    }
    cainiao_params.onerror = (res)=>{
      this.dialogShow = true
      window.cainiao_print_error == "提示: 菜鸟打印组件无法链接"
    }
    cainiao_params.onclose = (res)=>{
      this.$message.error("提示: 菜鸟打印组件无法链接");
      window.cainiao_print_error == "提示: 菜鸟打印组件无法链接"
    }
    window.cainiao_print = new cainiao_print(cainiao_params)

    
    // 初始化抖音打印机组件
    let douyin_params = new Object()
    douyin_params.onmessage= (event) => {
      console.log(event, "---onmessage")
      let response = JSON.parse(event.data);
      if (response.cmd == 'getPrinters') { // 监听打印机列表
        let print_list = response.printers.map(item=>{
          return item.name
        })
        console.log(print_list,'---抖音打印机列表获取成功')
        window.douyin_print_list = print_list // 别管用不用得上,先保存一份打印机列表
      }else if(response.cmd == 'print'){ // 监听打印事件
        console.log(response,'---print')
      }else if(response.cmd == 'getTaskStatus'){ // 监听打印事件
        console.log(response,'---getTaskStatus')
      }
    }
    douyin_params.onerror = (res)=>{
      window.douyin_print_error == "提示: 抖音打印组件无法链接"
    }
    douyin_params.onclose = (res)=>{
      window.douyin_print_error == "提示: 抖音打印组件无法链接"
    }
    window.douyin_print = new douyin_print(douyin_params)


    // 初始化快手打印机组件
    let kuaishou_params = new Object()
    kuaishou_params.onmessage= (event) => {
      console.log(event, "---onmessage")
      let response = JSON.parse(event.data);
      if (response.cmd == 'getPrinters') { // 监听打印机列表
        let print_list = response.printers.map(item=>{
          return item.name
        })
        console.log(print_list,'---快手打印机列表获取成功')
        window.kuaishou_print_list = print_list // 别管用不用得上,先保存一份打印机列表
      }else if(response.cmd == 'print'){ // 监听打印事件
        console.log(response,'---print')
      }else if(response.cmd == 'getTaskStatus'){ // 监听打印事件
        console.log(response,'---getTaskStatus')
      }
    }
    kuaishou_params.onerror = (res)=>{
      window.kuaishou_print_error == "提示: 快手打印组件无法链接"
    }
    kuaishou_params.onclose = (res)=>{
      window.kuaishou_print_error == "提示: 快手打印组件无法链接"
    }
    window.kuaishou_print = new kuaishou_print(kuaishou_params)
    
  }
};
</script>
<style lang="less" scoped>
.app-container {
  height: 100%;
}


</style>
